<template>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
    <v-layout
      v-if="hasAction('view_dashboard') || isAccountant() || isAdmin()"
      wrap>
      <v-layout wrap>
        <!-- <v-flex
          md12
          sm12
          lg4
        >
          <material-chart-card
            :data="dailySalesChart.data"
            :options="dailySalesChart.options"
            color="info"
            type="Line"
          >
            <h4 class="title font-weight-light">Daily Sales</h4>
            <p class="category d-inline-flex font-weight-light">
              <v-icon
                color="green"
                small
              >
                mdi-arrow-up
              </v-icon>
              <span class="green--text">55%</span>&nbsp;
              increase in today's sales
            </p>

            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey--text font-weight-light">updated 4 minutes ago</span>
            </template>
          </material-chart-card>
        </v-flex> -->
        <!-- <v-flex
          md12
          sm12
          lg4
        >
          <material-chart-card
            :data="emailsSubscriptionChart.data"
            :options="emailsSubscriptionChart.options"
            :responsive-options="emailsSubscriptionChart.responsiveOptions"
            color="red"
            type="Bar"
          >
            <h4 class="title font-weight-light">Email Subscription</h4>
            <p class="category d-inline-flex font-weight-light">Last Campaign Performance</p>

            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey--text font-weight-light">updated 10 minutes ago</span>
            </template>
          </material-chart-card>
        </v-flex> -->
        <!-- <v-flex
          md12
          sm12
          lg4
        >
          <material-chart-card
            :data="dataCompletedTasksChart.data"
            :options="dataCompletedTasksChart.options"
            color="green"
            type="Line"
          >
            <h3 class="title font-weight-light">Completed Tasks</h3>
            <p class="category d-inline-flex font-weight-light">Last Last Campaign Performance</p>

            <template slot="actions">
              <v-icon
                class="mr-2"
                small
              >
                mdi-clock-outline
              </v-icon>
              <span class="caption grey--text font-weight-light">campaign sent 26 minutes ago</span>
            </template>
          </material-chart-card>
        </v-flex> -->
        <v-flex
          v-show="!isUsageSite()"
          sm6
          xs12
          md6
          lg4
        >
          <material-stats-card
            :title="isAgent() ? $t('dashboard.voucherReceived') : $t('dashboard.voucherPublished')"
            :value="String(countVouchersAvailable + countVouchersIssued)"
            :sub-text="isAgent() ? $t('dashboard.numberOfVoucherReceived') : $t('dashboard.numberOfVoucherPublished')"
            color="green"
            icon="mdi-tag-multiple"
            sub-icon-color="success"
            sub-icon="mdi-counter"
            sub-text-color="text-success"
            @click="goVoucherListWithType(null)"
          />
        </v-flex>
        <v-flex
          v-show="!isUsageSite()"
          sm6
          xs12
          md6
          lg4
        >
          <material-stats-card
            :value="String(countVouchersAvailable)"
            :title="$t('dashboard.voucherUnreleased')"
            :sub-text="$t('dashboard.numberOfVoucherUnreleased')"
            color="orange"
            icon="mdi-tag-remove"
            sub-icon="mdi-counter"
            sub-icon-color="warning"
            sub-text-color="text-warning"
            @click="goVoucherListWithType(VoucherStatusType.NOT_ASSIGNED_YET)"
          />
        </v-flex>
        <v-flex
          v-show="!isUsageSite()"
          sm6
          xs12
          md6
          lg4
        >
          <material-stats-card
            :value="String(countVouchersIssued)"
            :title="$t('dashboard.voucherReleased')"
            :sub-text="$t('dashboard.numberOfVoucherReleased')"
            color="red"
            icon="mdi-tag-plus"
            sub-icon="mdi-counter"
            sub-icon-color="error"
            sub-text-color="text-red"
            @click="goVoucherListWithType(VoucherStatusType.ASSIGNED)"
          />
        </v-flex>
        <v-flex
          sm6
          xs12
          md6
          lg4
        >
          <material-stats-card
            :value="String(countVouchersDelivered)"
            :title="$t('dashboard.voucherDelivered')"
            :sub-text="$t('dashboard.numberOfVoucherDelivered')"
            color="info"
            icon="mdi-tag-faces"
            sub-icon="mdi-counter"
            sub-icon-color="info"
            sub-text-color="text-info"
            @click="goVoucherListWithType(VoucherStatusType.ACTIVATED)"
          />
        </v-flex>
        <v-flex
          sm6
          xs12
          md6
          lg4
        >
          <material-stats-card
            :value="String(countVouchersUsed)"
            :title="$t('dashboard.voucherUsed')"
            :sub-text="$t('dashboard.numberOfVoucherUsed')"
            color="blue"
            icon="mdi-tag-text-outline"
            sub-icon="mdi-counter"
            sub-icon-color="blue"
            sub-text-color="text-blue"
            @click="goVoucherListWithType(VoucherStatusType.USED)"
          />
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex
          md12
          lg6
        >
          <material-card
            :title="$t('dashboard.voucherRequestList')"
            :text="$t('dashboard.received')"
            color="red"
          >
            <v-data-table
              :no-data-text="$t('common.noDataAvailable')"
              :headers="issueVoucherRequestHeaders"
              :items="issueEVoucherReceiveList"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text--darken-3 text-red font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td class="text-xs-center">{{ item.stt }}</td>
                <td class="text-xs-center">{{ item.name }}</td>
                <td class="text-xs-center">{{ item.quantity }}</td>
                <td class="text-xs-center">{{ item.note }}</td>
                <td class="text-xs-center">{{ $t(getStatusText(item.status)) }}</td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
        <v-flex
          md12
          lg6
        >
          <material-card
            :title="$t('dashboard.voucherRequestList')"
            :text="$t('dashboard.sent')"
            color="purple"
          >
            <v-data-table
              :no-data-text="$t('common.noDataAvailable')"
              :headers="issueVoucherRequestHeaders"
              :items="issueVoucherRequestList"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="text-purple text--darken-3 font-medium"
                  v-text="$t(header.text)"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td class="text-xs-center">{{ item.stt }}</td>
                <td class="text-xs-center">{{ item.name }}</td>
                <td class="text-xs-center">{{ item.quantity }}</td>
                <td class="text-xs-center">{{ item.note }}</td>
                <td class="text-xs-center">{{ $t(getStatusText(item.status)) }}</td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
        <!-- <v-flex
          md12
          lg6
        >
          <material-card
            class="card-tabs"
            color="green">
            <v-flex
              slot="header"
            >
              <v-tabs
                v-model="tabs"
                color="transparent"
                slider-color="white"
              >
                <span
                  class="subheading font-weight-light mr-3"
                  style="align-self: center"
                >Tasks:</span>
                <v-tab class="mr-3">
                  <v-icon class="mr-2">mdi-bug</v-icon>
                  Bugs
                </v-tab>
                <v-tab class="mr-3">
                  <v-icon class="mr-2">mdi-code-tags</v-icon>
                  Website
                </v-tab>
                <v-tab>
                  <v-icon class="mr-2">mdi-cloud</v-icon>
                  Server
                </v-tab>
              </v-tabs>
            </v-flex>

            <v-tabs-items v-model="tabs">
              <v-tab-item
                v-for="n in 3"
                :key="n"
              >
                <v-list three-line>
                  <v-list-tile @click="complete(0)">
                    <v-list-tile-action>
                      <v-checkbox
                        :value="list[0]"
                        color="green"
                      />
                    </v-list-tile-action>
                    <v-list-tile-title>
                      Sign contract for "What are conference organized afraid of?"
                    </v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon
                        >
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>

                    </div>
                  </v-list-tile>
                  <v-divider/>
                  <v-list-tile @click="complete(1)">
                    <v-list-tile-action>
                      <v-checkbox
                        :value="list[1]"
                        color="success"
                      />
                    </v-list-tile-action>
                    <v-list-tile-title>
                      Lines From Great Russian Literature? Or E-mails From My Boss?
                    </v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                        <span>Edit</span>
                      </v-tooltip>

                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon>
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>
                    </div>
                  </v-list-tile>
                  <v-divider/>
                  <v-list-tile @click="complete(2)">
                    <v-list-tile-action>
                      <v-checkbox
                        :value="list[2]"
                        color="success"
                      />
                    </v-list-tile-action>
                    <v-list-tile-title>
                      Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit
                    </v-list-tile-title>
                    <div class="d-flex">
                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="success"
                          icon
                        >
                          <v-icon color="primary">mdi-pencil</v-icon>
                        </v-btn>
                        <span>Edit</span>
                      </v-tooltip>
                      <v-tooltip
                        top
                        content-class="top">
                        <v-btn
                          slot="activator"
                          class="v-btn--simple"
                          color="danger"
                          icon
                        >
                          <v-icon color="error">mdi-close</v-icon>
                        </v-btn>
                        <span>Close</span>
                      </v-tooltip>

                    </div>
                  </v-list-tile>
                </v-list>
              </v-tab-item>
            </v-tabs-items>
          </material-card>
        </v-flex> -->
      </v-layout>
    </v-layout>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import VoucherRequestType from 'enum/voucherRequestType'
import VoucherRequestStatus from 'enum/voucherRequestStatus'
import functionUtils from 'utils/functionUtils'
import VoucherStatusType from 'enum/voucherStatusType'
import EntityType from 'enum/entityType'
import EntityRoleType from 'enum/entityRoleType'
export default {
  data () {
    return {
      dailySalesChart: {
        data: {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [
            [12, 17, 7, 17, 23, 18, 38]
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
          series: [
            [230, 750, 450, 300, 280, 240, 200, 190]
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: ['Ja', 'Fe', 'Ma', 'Ap', 'Mai', 'Ju', 'Jul', 'Au', 'Se', 'Oc', 'No', 'De'],
          series: [
            [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

          ]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      headers: [
        {
          sortable: false,
          text: 'ID',
          value: 'id'
        },
        {
          sortable: false,
          text: 'Name',
          value: 'name'
        },
        {
          sortable: false,
          text: 'Salary',
          value: 'salary',
          align: 'right'
        },
        {
          sortable: false,
          text: 'Country',
          value: 'country',
          align: 'right'
        },
        {
          sortable: false,
          text: 'City',
          value: 'city',
          align: 'right'
        }
      ],
      items: [
        {
          name: 'Dakota Rice',
          country: 'Niger',
          city: 'Oud-Tunrhout',
          salary: '$35,738'
        },
        {
          name: 'Minerva Hooper',
          country: 'Curaçao',
          city: 'Sinaai-Waas',
          salary: '$23,738'
        }, {
          name: 'Sage Rodriguez',
          country: 'Netherlands',
          city: 'Overland Park',
          salary: '$56,142'
        }, {
          name: 'Philip Chanley',
          country: 'Korea, South',
          city: 'Gloucester',
          salary: '$38,735'
        }, {
          name: 'Doris Greene',
          country: 'Malawi',
          city: 'Feldkirchen in Kārnten',
          salary: '$63,542'
        }
      ],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false
      },
      countVouchersAvailable: 0,
      countVouchersIssued: 0,
      countVouchersDelivered: 0,
      countVouchersUsed: 0,
      issueVoucherRequestHeaders: [
        {
          sortable: false,
          text: 'common.nonumber',
          value: 'stt',
          align: 'center'
        },
        {
          sortable: false,
          text: 'issueVoucherRequest.title',
          value: 'name',
          align: 'center'
        },
        {
          sortable: false,
          text: 'issueVoucherRequest.quantity',
          value: 'quantity',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.note',
          value: 'note',
          align: 'center'
        },
        {
          sortable: false,
          text: 'common.status',
          value: 'status',
          align: 'center'
        }
      ],
      issueVoucherRequestList: [],
      requestPaginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      issueEVoucherReceiveList: [],
      receivePaginate: {
        totalPage: 1,
        currentPage: 1,
        rowPerPage: 1
      },
      VoucherStatusType: VoucherStatusType
    }
  },
  computed: {
    ...mapGetters([
      'VOUCHER_STATISTICS_DATA',
      'GET_CURRENT_ACCOUNT'
    ])
  },
  watch: {
    GET_CURRENT_ACCOUNT: function () {
      this.countStatisticsEVoucher()
    },
    VOUCHER_STATISTICS_DATA () {
      let data = this.VOUCHER_STATISTICS_DATA
      this.countVouchersAvailable = data.count_vouchers_available
      this.countVouchersIssued = data.count_vouchers_issued
      this.countVouchersDelivered = data.count_vouchers_deliveried
      this.countVouchersUsed = data.count_vouchers_used
    }
  },
  created () {
    this.countStatisticsEVoucher()
  },
  methods: {
    isAdmin: function () {
      return functionUtils.isAdmin(this.GET_CURRENT_ACCOUNT)
    },
    isAgent: function () {
      return functionUtils.isEntityType(EntityType.AGENT)
    },
    isSupplier: function () {
      return functionUtils.isEntityType(EntityType.SUPPLIER)
    },
    isUsageSite: function () {
      return functionUtils.isEntityType(EntityType.SITE)
    },
    isAccountant: function () {
      return functionUtils.roleInEntity(EntityRoleType.ACCOUNTANT)
    },
    /**
     * Check has action
     */
    hasAction: function (metaCode) {
      return functionUtils.hasAction(this.GET_CURRENT_ACCOUNT, metaCode)
    },
    /**
     * Count statistics e voucher
     */
    countStatisticsEVoucher: function () {
      this.GET_VOUCHER_STATISTICS()
      this.getIssueEVoucherRequest()
      this.getIssueEVoucherReceive()
    },
    /**
     * Go voucher list with type
     */
    goVoucherListWithType: function (type) {
      this.$router.push({
        path: '/e-voucher-list',
        query: {
          type: type
        }
      })
    },
    /**
     * Get status text
     */
    getStatusText: function (status) {
      if (status === VoucherRequestStatus.INITIAL) {
        return 'issueVoucherRequest.initial'
      } else if (status === VoucherRequestStatus.APPROVE) {
        return 'issueVoucherRequest.approved'
      } else if (status === VoucherRequestStatus.CANCEL) {
        return 'issueVoucherRequest.canceled'
      } else {
        return ''
      }
    },
    /**
     * Get issue e voucher receive
     */
    getIssueEVoucherReceive: function () {
      let filter = {
        params: {
          page: this.receivePaginate.currentPage,
          type: VoucherRequestType.RECEIVE
        }
      }
      this.GET_ISSUE_VOUCHER_REQUEST_BY_TYPE(filter).then(
        function (res) {
          let data = res.data
          this.receivePaginate.currentPage = data.page
          this.receivePaginate.totalPage = data.num_pages
          this.receivePaginate.rowPerPage = data.per_page / 2
          let results = data.results
          this.issueEVoucherReceiveList = []
          for (let i = 0, size = results.length; i < size; i++) {
            let obj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: results[i].id,
              name: results[i].name,
              quantity: results[i].quantity,
              note: results[i].note,
              status: results[i].status,
              isLoadingApprove: false,
              isLoadingCancel: false
            }
            this.issueEVoucherReceiveList.push(obj)
          }
        }.bind(this)
      )
    },
    /**
     * Get issue e voucher request
     */
    getIssueEVoucherRequest: function () {
      let filter = {
        params: {
          page: this.requestPaginate.currentPage,
          type: VoucherRequestType.REQUEST
        }
      }
      this.GET_ISSUE_VOUCHER_REQUEST_BY_TYPE(filter).then(
        function (res) {
          let data = res.data
          this.requestPaginate.currentPage = data.page
          this.requestPaginate.totalPage = data.num_pages
          this.requestPaginate.rowPerPage = data.per_page / 2
          let results = data.results
          this.issueVoucherRequestList = []
          for (let i = 0, size = results.length; i < size; i++) {
            let obj = {
              stt: i + 1 + (data.page - 1) * data.page_size,
              id: results[i].id,
              name: results[i].name,
              quantity: results[i].quantity,
              note: results[i].note,
              status: results[i].status,
              isLoadingApprove: false,
              isLoadingCancel: false
            }
            this.issueVoucherRequestList.push(obj)
          }
        }.bind(this)
      )
    },
    complete (index) {
      this.list[index] = !this.list[index]
    },
    ...mapActions([
      'GET_VOUCHER_STATISTICS',
      'GET_ISSUE_VOUCHER_REQUEST_BY_TYPE'
    ])
  }
}
</script>

<style lang="scss" scoped>
.container.grid-list-xl .layout .flex {
  cursor: pointer;
}
</style>
